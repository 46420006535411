import React, { useMemo } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';

// Utils
import headUtils from '../../utils/headUtils';
import utils from '../../utils/formatCMS';
import searchEngineUtils from '../../utils/searchEngineUtils';
import { getBreadcrumbListSchema } from '../../utils/seoUtils';

// Components
import Menu from '../Molecules/Menu';
import DynamicPagesHeader from '../Molecules/DynamicPagesHeader';
import DynamicPagesTextBlock from '../Molecules/DynamicPagesTextBlock';
import ReassuranceBanner from '../Molecules/ReassuranceBanner';
import Footer from '../Molecules/Footer';
import ListItem from '../Atoms/ListItem';
import SearchEngine from './SearchEngine';

// Images
import logo from '../../public/Images/logo.webp';

// Hooks
import useIsMobile from '../../hooks/useIsMobile';
import strings from '../../constants/Strings';
import Breadcrumb from '../Atoms/Breadcrumb';

const SeoPageTemplate = (props) => {
  const [isMobile] = useIsMobile();
  const router = useRouter();

  const breadcrumb = useMemo(() => {
    let breadcrumbList = [];

    if (props?.parents?.length > 0) {
      breadcrumbList = props.parents.map((parent) => ({
        name: parent?.titre,
        slug: parent?.slug,
      }));
    }
    breadcrumbList.push({
      name: props?.pageContent?.titre,
      slug: props?.pageContent?.slug,
    });

    return breadcrumbList;
  }, [props]);

  const titleTag = headUtils.formatPageTitle(props?.pageContent?.metaTitle || props?.pageContent?.titre);
  const descriptionTag = props?.pageContent?.metaDescription
  || headUtils.formatMetaDescription(props?.pageContent?.introduction?.content?.[0]?.content?.[0]?.value)
  || headUtils.formatMetaDescription(props?.pageContent?.contenu?.content?.[0]?.content?.[0]?.value)
  || headUtils.formatMetaDescription(props?.pageContent?.contenu?.content?.[1]?.content?.[0]?.value);

  return (
    <div>
      <Head>
        <title>{titleTag}</title>
        <meta property="og:title" content={titleTag} />
        <meta name="description" content={descriptionTag} />
        <meta property="og:description" content={descriptionTag} />
        <meta
          property="og:image"
          content={props.pageContent.imageDillustration?.fields?.file?.url
            ? `https:${props.pageContent.imageDillustration?.fields?.file?.url}`
            : `https://${process.env.NEXT_PUBLIC_DOMAIN}/Images/og-image.png`}
        />
        <meta
          property="og:image:secure_url"
          content={props.pageContent.imageDillustration?.fields?.file?.url
            ? `https:${props.pageContent.imageDillustration?.fields?.file?.url}`
            : `https://${process.env.NEXT_PUBLIC_DOMAIN}/Images/og-image.png`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: getBreadcrumbListSchema(breadcrumb),
          }}
        />
      </Head>
      {/* header */}
      <div className="full-width d-flex f-row relative">
        {/* Menu */}
        <div className="absolute absolute-right-top-corner index-2">
          <Menu
            items={utils.formatCMSDatas('menu', props)}
            fontColor="grey-800-text"
            mobileTheme="dark"
            textColor={props.pageContent.imageDillustration ? 'white-text' : 'grey-800-text'}
          />
        </div>
        <div className="absolute index-2 mv-2 mh-5">
          {isMobile || props.pageContent.imageDillustration ? null : (
            <Link href="/" className="m-3">
              <a><Image alt="logo" src={logo} width={206} height={206} /></a>
            </Link>
          )}
        </div>
      </div>
      <div
        className="relative full-width white-background d-flex justify-center align-center f-column"
      >
        { props.parents && !props.pageContent.imageDillustration
          ? (
            <div
              className="fgIndex ph-3"
              style={{
                width: isMobile ? '100%' : '80%',
                marginTop: 140,
              }}
            >
              <Breadcrumb parents={props.parents} currentTitle={props.pageContent.titre} />
            </div>
          )
          : null}
        <div
          className="full-width baseIndex"
          style={{
            marginTop: props.parents || props.pageContent.imageDillustration ? 0 : 140,
            width: isMobile || props.pageContent.imageDillustration ? '100%' : '80%',
          }}
        >
          <DynamicPagesHeader
            title={props.pageContent.titre}
            intro={props.pageContent.introduction}
            illustration={props.pageContent.imageDillustration?.fields?.file?.url}
          />
        </div>
        { props.parents && props.pageContent.imageDillustration
          ? (
            <div
              className="ph-5 full-width"
            >
              <Breadcrumb parents={props.parents} currentTitle={props.pageContent.titre} />
            </div>
          )
          : null}
        {
          props.pageContent?.afficherLeMoteurDeRecherche
            ? (
              <div className={`grey-800-background
        ${isMobile ? 'ph-2 pv-4' : 'p-5'} full-width d-flex justify-center align-center f-column`}
              >
                <p className="h2 white-text medium-weight m-0 text-align-center">{strings.findYourHeatingPro}</p>
                <p className={`grey-400-text normal-weight text-align-center ${isMobile ? 'ph-2' : 'ph-6'} mv-4`}>
                  {strings.findYourHeatingProSubtitle}
                </p>
                <div className="d-flex justify-center align-center white-background radius-5">
                  <SearchEngine
                    theme="light"
                    withLogo={false}
                    withLabel
                    handleSubmit={(data) => searchEngineUtils.goToSearchPage(router, data)}
                    initValues={{
                      need: props.pageContent?.typeDeDemande,
                      equipment: props.pageContent?.typeDappareil,
                    }}
                  />
                </div>
              </div>
            )
            : null
        }
        <DynamicPagesTextBlock
          content={props.pageContent.contenu}
          hasBackground={false}
        />
        <div className="grey-100-background d-flex justify-center mb-2 full-width">
          <div
            className="ph-3"
            style={{
              width: isMobile ? '100%' : '80%',
            }}
          >
            {
              props.siblings?.map(({ slug, titre }) => (
                <ListItem
                  key={slug}
                  to={`/${slug}`}
                  title={titre}
                />
              ))
          }
          </div>
        </div>
      </div>
      {/* Reassurance banner */}
      <ReassuranceBanner items={utils.formatCMSReassurance(props)} />
      {/* Footer */}
      <Footer
        networkBtn={props.footerRseauLienBouton}
        clientItems={utils.formatCMSDatas('footerClient', props)}
        networkItems={utils.formatCMSDatas('footerRseau', props)}
        facebook={props.footerFacebook}
        instagram={props.footerInstagram}
        twitter={props.footerTwitter}
        mentions={props.mentions}
        legalItems={utils.formatCMSDatas('sousFooter', props)}
        isMobile={isMobile}
      />
    </div>
  );
};

SeoPageTemplate.propTypes = {

};

export default SeoPageTemplate;
