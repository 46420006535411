import React from 'react';
import PropTypes from 'prop-types';

const AssetHyperlink = ({ title, file }) => (
  <a href={`https://${file.url}`} rel="noreferrer" target="_blank">
    {title}
  </a>
);

AssetHyperlink.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
};

export default AssetHyperlink;
