import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import { useMeasure } from 'react-use';

// Components
import AssetHyperlink from '../Atoms/AssetHyperlink';

// Hooks
import useIsMobile from '../../hooks/useIsMobile';

// Constants
import colors from '../../constants/Colors';

const options = {
  renderNode: {
    'asset-hyperlink': (node) => {
      const { title, file } = node.data.target.fields;
      return <AssetHyperlink title={title} file={file} />;
    },
    'embedded-asset-block': (node) => {
      const { title } = node.data.target.fields;
      const { width, height } = node.data.target.fields?.file?.details?.image;
      return (
        <div className="d-flex justify-center">
          <Image
            loader={({ src }) => `https:${src}`}
            src={node?.data?.target?.fields?.file?.url}
            width={width}
            height={height}
            className="bgIndex"
            alt={title}
          />
        </div>
      );
    },
  },
};

const DynamicPagesTextBlock = ({ content, hasBackground }) => {
  const [isMobile] = useIsMobile();
  const [contentRef, { height: contentHeight }] = useMeasure();

  return (
    <div
      className="relative full-width d-flex justify-center red-link"
      style={{ height: contentHeight + 32 }}
    >
      { hasBackground ? (
        <div className="absolute absolute-left-top-corner full-width">
          {isMobile ? null : (
            <SlashContainer width="41%" backgroundColor={colors.grey100} offset={20}>
              <div style={{ height: contentHeight + 32 }} />
            </SlashContainer>
          )}
        </div>
      ) : null}
      <div
        ref={contentRef}
        className="fgIndex absolute"
        style={{
          width: isMobile ? '100%' : '80%',
        }}
      >
        <div
          style={{ maxWidth: 1000 }}
          className="grey-600-text ph-3 wysiwyg-text ws-pre-wrap mv-4 mh-3"
        >
          {documentToReactComponents(content, options)}
        </div>
      </div>
    </div>
  );
};

DynamicPagesTextBlock.propTypes = {
  content: PropTypes.shape().isRequired,
  hasBackground: PropTypes.bool,
};

DynamicPagesTextBlock.defaultProps = {
  hasBackground: false,
};

export default DynamicPagesTextBlock;
