import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const Breadcrumb = ({ parents, currentTitle }) => (
  <div className="small-text d-flex f-wrap">
    {
      parents?.map(({ slug, titre }) => (
        <div key={slug}>
          <Link href={`/${slug}`}>
            <a className="grey-800-text">
              {titre}
            </a>
          </Link>
          <span className="grey-800-text mh-2">{'>'}</span>
        </div>
      ))
    }
    <span className="primary-red-text">
      {currentTitle}
    </span>
  </div>
);

Breadcrumb.propTypes = {
  parents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentTitle: PropTypes.string.isRequired,
};

export default Breadcrumb;
