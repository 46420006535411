import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import Link from 'next/link';
import Image from 'next/image';
// Constants
import strings from '../../constants/Strings';
// Images
import whiteArrow from '../../public/icons/arrow-big-white.svg';
// Hooks
import useIsMobile from '../../hooks/useIsMobile';

const ListItem = ({
  title,
  to,
}) => {
  const [isHovered, setIsHover] = useState(false);
  const [isMobile] = useIsMobile();

  return (
    <div
      className="d-flex f-column mt-3"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={`d-flex ${isMobile ? 'f-column' : 'f-row justify-between align-center'} mt-3`}>
        <div className="d-flex f-column" style={{ width: isMobile ? '100%' : 'calc(100% - 250px)' }}>
          <div>
            <Link href={to}>
              <a>
                <span className={`${isHovered ? 'primary-red-text' : 'grey-800-text'} medium-weight h5 mr-3`}>
                  {title}
                </span>
              </a>
            </Link>
          </div>
        </div>
        <div>
          {isHovered || isMobile ? (
            <ButtonComponent>
              <Link href={to}>
                <a>
                  <div
                    className="d-flex justify-center align-center mv-2 mh-4"
                  >
                    <span className="normal-weight ws-no-wrap">{strings.learnMore}</span>
                    <div className="ml-2">
                      <Image src={whiteArrow} width={25} alt="arrow" />
                    </div>
                  </div>
                </a>
              </Link>

            </ButtonComponent>
          ) : null}
        </div>
      </div>
      <div className="line grey-800-background mt-3 opacity-01" />
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default ListItem;
