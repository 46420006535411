import React from 'react';
import PropTypes from 'prop-types';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Link from 'next/link';
import Image from 'next/image';
// Hooks
import useIsMobile from '../../hooks/useIsMobile';
// Constants
import colors from '../../constants/Colors';
// Images
import logo from '../../public/Images/logo.webp';
// Components
import HeaderWithSlashContainer from '../Atoms/HeaderWithSlashContainer';

const DynamicPagesHeader = ({
  title, intro, illustration,
}) => {
  const [isMobile] = useIsMobile();
  const renderWithoutImage = () => (
    <>
      <div className="absolute absolute-left-top-corner full-width rotate-180 bgIndex">
        {isMobile ? null : (
          <SlashContainer
            width="45%"
            backgroundColor="linear-gradient(to top, #F4F5F9 0%, rgba(244,245,249,0) 100%)"
            offset={20}
          >
            <div style={{ height: 500 }} />
          </SlashContainer>
        )}
      </div>
      <div
        className="fgIndex red-link"
        style={{
          width: isMobile ? '100%' : '80%',
        }}
      >
        <h1
          className="grey-800-text medium-weight ph-3"
          style={{ maxWidth: 1000 }}
        >
          {title}
        </h1>
        <div
          className="grey-600-text ph-3 wysiwyg-text ws-pre-wrap mv-3"
          style={{ maxWidth: 1000 }}
        >
          {documentToReactComponents(intro)}
        </div>
      </div>
    </>
  );

  const renderWithImage = () => (
    <div className="d-flex f-row full-width">
      <HeaderWithSlashContainer
        backgroundColor={colors.white}
        imageUrl={`${illustration}?fm=webp&w=${
          isMobile ? 820 : 1080}&h=${isMobile ? 200 : 730}`}
      >
        {isMobile ? null : (
          <Link href="/" className="mt-3 mb-4">
            <a><Image alt="logo" src={logo} width={206} height={206} /></a>
          </Link>
        )}
        <div className={`${isMobile ? 'mv-2 pl-3' : 'mb-6 mt-4'}`}>
          <div className="ml-4 mv-4 red-link" style={{ minHeight: 300 }}>
            <h1 className="medium-weight grey-800-text m-0 mb-4">{title}</h1>
            <div
              className="grey-400-text normal-weight wysiwyg-text ws-pre-wrap"
            >
              {documentToReactComponents(intro)}
            </div>
          </div>
        </div>
      </HeaderWithSlashContainer>
    </div>
  );

  return illustration ? renderWithImage() : renderWithoutImage();
};

DynamicPagesHeader.propTypes = {
  title: PropTypes.string.isRequired,
  illustration: PropTypes.string,
  intro: PropTypes.shape().isRequired,
};

DynamicPagesHeader.defaultProps = {
  illustration: null,
};

export default DynamicPagesHeader;
